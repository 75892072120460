var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"setup-container"},[_c('div',{staticClass:"setup-content",attrs:{"data-testid":"section-setup-guide"}},[_c('b-card-title',{staticClass:"title-content padding-x-24"},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.titleStepGuide'))+" ")]),_c('div',{staticClass:"steps-setup"},[_c('div',{staticClass:"wizard-radios-container"},[_c('b-card-title',{staticClass:"text-title padding-x-24"},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.textGetStarted'))+" ")]),_c('div',{staticClass:"nav-menu"},[_c('ul',{staticClass:"wizard get-started",attrs:{"id":"list-step-guide","data-testid":"list-step-guide"}},_vm._l((_vm.getStarted),function(item,index){return _c('li',{key:index,staticClass:"item-list padding-x-24",attrs:{"id":("item-step-guide-" + (index+1)),"data-testid":("item-step-guide-" + (index+1)),"title":item.title},on:{"click":function($event){return _vm.handleUrlStep(item.id)}}},[_c('div',{staticClass:"wizard-item",class:{
                  'wizard-item__disable': item.disable,
                  'wizard-item__visited': item.visited,
                  'wizard-item__active': item.active,
                },attrs:{"id":("content-step-" + (index+1)),"data-testid":("content-step-" + (index+1))}},[_c('div',{staticClass:"item-content",attrs:{"id":item.id,"data-testid":((item.id) + "-step-guide")}},[_c('div',{staticClass:"id-item",class:{
                      'id-item__visited': item.visited,
                      'id-item__active': item.active,
                    },attrs:{"data-testid":("number-" + (item.id) + "step")}},[(item.active)?_c('feather-icon',{attrs:{"icon":"CheckIcon","size":"14"}}):_c('span',[_vm._v(" "+_vm._s(item.id)+" ")])],1),_c('div',[_c('div',{staticClass:"title-item"},[_c('b-card-title',{class:{'color-item-active': !item.disable||item.visited, 'color-item-visited': item.visited},attrs:{"id":("title-step-" + (index+1)),"data-testid":("title-step-" + (index+1))}},[_vm._v(" "+_vm._s(item.title)+" ")])],1),_c('div',{staticClass:"content-item",attrs:{"id":("desc-step-" + (index+1)),"data-testid":("desc-step-" + (index+1))}},[_c('b-card-text',[_vm._v(" "+_vm._s(item.desc)+" ")])],1)])])])])}),0)])],1),_c('div',{staticClass:"wizard-radios-container"},[_c('b-card-title',{staticClass:"text-title padding-x-24 mb-0"},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.textRunYourCampaign'))+" ")]),_c('div',{staticClass:"nav-menu"},[_c('ul',{staticClass:"wizard run-campaign pt-2",attrs:{"id":"list-step-run-campaign","data-testid":"list-step-run-campaign"}},_vm._l((_vm.runYourCampaign),function(item,index){return _c('li',{key:index,staticClass:"item-list padding-x-24",attrs:{"id":("item-step-run-campaign-" + (index+1)),"data-testid":("item-step-run-campaign-" + (index+1)),"title":item.title},on:{"click":function($event){return _vm.handleUrlStep(item.id)}}},[_c('div',{staticClass:"wizard-item",class:{
                  'wizard-item__disable': item.disable,
                  'wizard-item__visited': item.visited,
                  'wizard-item__active': item.active,
                },attrs:{"id":("content-step-run-campaign-" + (index+1)),"data-testid":("content-step-run-campaign-" + (index+1))}},[_c('div',{staticClass:"item-content",attrs:{"id":item.id}},[_c('div',{staticClass:"id-item",class:{
                      'id-item__visited': item.visited,
                      'id-item__active': item.active,
                    }},[(item.active === true)?_c('feather-icon',{attrs:{"icon":"CheckIcon","size":"14"}}):_c('span',[_vm._v(" "+_vm._s(item.id)+" ")])],1),_c('div',[_c('div',{staticClass:"title-item"},[_c('b-card-title',{class:{'color-item-active': !item.disable||item.visited, 'color-item-visited': item.visited},attrs:{"id":("title-step-run-campaign-" + (index+1)),"data-testid":("title-step-run-campaign-" + (index+1))}},[_vm._v(" "+_vm._s(item.title)+" ")])],1),_c('div',{staticClass:"content-item"},[_c('b-card-text',{attrs:{"id":("desc-step-run-campaign-" + (index+1)),"data-testid":("desc-step-run-campaign-" + (index+1))}},[_vm._v(" "+_vm._s(item.desc)+" ")]),(item.subDesc)?_c('div',[_c('ul',{staticClass:"sub-desc"},_vm._l((item.subDesc),function(text,id){return _c('li',{key:id},[_vm._v(" "+_vm._s(text)+" ")])}),0)]):_vm._e()],1)])])])])}),0)])],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }