<template>
  <b-modal
    ref="verifyEmailRef"
    ok-title="Confirm"
    size="w-refund"
    hide-footer
    hide-header
    hide-header-close
    centered
    no-close-on-backdrop
  >
    <!--Content-->
    <div class="d-flex justify-content-end top-modal">
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>
    <div
      class="d-flex flex-column align-items-center"
    >
      <b-img
        rounded
        class="ml-1"
        :src="require('@/assets/images/pages/auth/email-verify.png')"
        style="width: 120px"
      />
      <h4 class="mt-1">
        {{ $t('home.titleEmailSendSuccessfullyModal') }}
      </h4>
      <p class="mt-1 text-center">{{ $t('home.contentNoticeSendEmail') }} <span class="text-danger">{{ user.data.email }}</span>,
        {{ $t('home.textPleaseCheckYourInbox') }}.
      </p>
    </div>
    <!--modal footer-->
    <div class="d-flex justify-content-end mt-2">
      <btn-loading
        class="cursor-pointer"
        variant-convert="flat-secondary"
        pill
        @click="hideModal"
      >
        {{ $t('common.btnClose') }}
      </btn-loading>
    </div>
    <!-- modal footer -->
  </b-modal>
</template>

<script>
import { BModal, BImg } from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

const { mapGetters } = createNamespacedHelpers('auth')

export default {
  name: 'VerifyEmailSuccessModal',
  components: {
    BtnLoading,
    BModal,
    BImg,
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    showModal() {
      this.$refs.verifyEmailRef.show()
    },
    hideModal() {
      this.$refs.verifyEmailRef.hide()
    },
  },
}
</script>

<style scoped lang="scss">

</style>
