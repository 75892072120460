<template>
  <div
    class="w-100 d-flex align-items-center box text-primary font-14 px-2"
  >
    <img
      class="alert-img"
      alt="alert"
      :src="require('@/assets/images/common/ic-alert.svg')"
    >
    <span v-if="title">
      {{ title }}
    </span>
    <span v-else>
      <slot name="title" />
    </span>
  </div>
</template>
<script>
export default {
  name: 'AppProfileAlert',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}

</script>
<style lang="scss" scoped>
  .box{
    background-color: rgba(251, 176, 59, 0.1);
    height: 40px;
    //position: fixed;
    top: 0;
    z-index: 1 !important;

    .alert-img{
      margin-right: 5px;
    }
  }
</style>
<style lang="scss">
  .navbar-floating .header-navbar-shadow{
    display: none !important;
  }
</style>
