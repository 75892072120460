<template>
  <div class="mb-2">
    <b-card-title>A quick setup guide for your business.</b-card-title>
    <b-img
      v-if="false"
      :src="require('@/assets/images/pages/overview/banner-overview.png')"
      alt="banner header"
      class="w-100 banner-content cursor-pointer"
      @click="goTo('https://blog.ecomdymedia.com/blog/2024-tiktok-agency-ad-account-kickstarter-with-ecomdy')"
    />
    <b-row class="step-content">
      <b-col
        cols="5"
        class="p-0"
      >
        <e-charts
          ref="line"
          class="chart-step"
          autoresize
          :options="option"
          auto-resize
        />
      </b-col>
      <b-col
        cols="7"
        class="p-0"
      >
        <div class="title-item">
          <b-card-title class="mb-0">
            {{ titleStep }}
          </b-card-title>
        </div>
        <div class="content-item font-14">
          <b-card-text class="mb-1">
            {{ desStep }}
          </b-card-text>
          <div v-if="subDesStep">
            <ul
              class="m-0"
              style="list-style: decimal"
            >
              <li
                v-for="(text, id) in subDesStep"
                :key="id"
              >
                {{ text }}
              </li>
            </ul>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ECharts from 'vue-echarts'
// import * as echarts from 'echarts/lib/echarts.js'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/pie'
import { createNamespacedHelpers } from 'vuex'
import { ONBOARDING_STEP } from '@/constants'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import partnerMixin from '@/mixins/partnerMixin'
import {
  BRow, BCol, BCardTitle, BCardText, BImg,
} from 'bootstrap-vue'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')
export default {
  components: {
    ECharts,
    BImg,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
  },
  mixins: [stepGuideMixin, partnerMixin],

  data() {
    return {
      titleStep: '',
      desStep: '',
      subDesStep: '',
      option: {
        tooltip: false,
        legend: false,
        label: {
          show: false,
        },
        series: [
          {
            name: 'Step Guide',
            type: 'pie',
            radius: ['55%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            emphasis: {
              disabled: true,
            },
            labelLine: {
              show: false,
            },
            color: ['#4CB375', '#F5F5F7', '#F5F5F7', '#F5F5F7', '#F5F5F7'],
            data: [
              {
                value: 20,
                name: 'Step 1',
              },
              {
                value: 20,
                name: 'Step 2',
              },
              {
                value: 20,
                name: 'Step 3',
              },
              {
                value: 20,
                name: 'Step 4',
              },
              {
                value: 20,
                name: 'Step 5',
              },
            ],
          },
        ],
      },
    }
  },

  computed: {
    ...mapGettersAuth(['user']),
  },

  watch: {
    user: {
      handler(userInfo) {
        if (userInfo) {
          const step = userInfo?.data?.onboardingStep
          this.handleStep(step)
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    const step = this.user?.data?.onboardingStep
    const { fullPath } = this.$route
    const checkUrl = fullPath.toLowerCase()
      .includes('/payment/failed')

    if (checkUrl) {
      this.$router.push(fullPath)
    } else {
      this.handleStep(step)
    }
  },

  methods: {
    handleStep(step) {
      if (this.isPartnerViewMode === 'true') {
        this.$router.push({ name: 'partner-management' })
      } else if (step <= this.setupGuideStep && this.hasStepGuide && step !== 1) {
        switch (step) {
          case ONBOARDING_STEP.UPDATING_CONTACT_INFO: {
            this.option.series[0].color = ['#4CB375', '#FF9903', '#F5F5F7', '#F5F5F7', '#F5F5F7']
            this.titleStep = 'Update Information'
            this.desStep = 'Information for run campaign.'
            break
          }
          case ONBOARDING_STEP.WAITING_VERIFY_CONTACT_INFO: {
            this.option.series[0].color = ['#4CB375', '#4CB375', '#FF9903', '#F5F5F7', '#F5F5F7']
            this.titleStep = 'Waiting verify information'
            this.desStep = 'Waiting for admin verify information.'
            break
          }
          case ONBOARDING_STEP.DEPOSITING_MONEY: {
            this.option.series[0].color = ['#4CB375', '#4CB375', '#4CB375', '#FF9903', '#F5F5F7']
            this.titleStep = 'Add fund'
            this.desStep = 'Add fund to Ecomdy balance'
            break
          }
          case ONBOARDING_STEP.REQUESTING_ADS_ACCOUNT: {
            this.option.series[0].color = ['#4CB375', '#4CB375', '#4CB375', '#4CB375', '#FF9903']
            this.titleStep = 'Create ads account'
            this.desStep = 'Take a look at how your TikTok ads account works.'
            break
          }
          default: {
            this.option.series[0].color = ['#4CB375', '#4CB375', '#4CB375', '#4CB375', '#4CB375']
          }
        }
      }
    },

    goTo(url) {
      window.open(url)
    },
  },
}
</script>

<style lang="scss" scoped>
.banner-content {
  border-radius: 16px;
  margin-bottom: 24px;
}

.echarts {
  width: 100%;
}

.step-content {
  align-items: center;
  background: #FFFFFF;
  border-radius: 16px;
  padding: 20px 20px 20px 0px;
  margin: 0;

  .chart-step {
    height: 140px;
  }
}

.title-item {
  font-size: 16px;
}
</style>
