<template>
  <div class="setup-container">
    <div
      data-testid="section-setup-guide"
      class="setup-content"
    >
      <b-card-title class="title-content padding-x-24">
        {{ $t('stepGuideControl.titleStepGuide') }}
      </b-card-title>
      <div class="steps-setup">
        <div class="wizard-radios-container">
          <b-card-title class="text-title padding-x-24">
            {{ $t('stepGuideControl.textGetStarted') }}
          </b-card-title>
          <div class="nav-menu">
            <ul
              id="list-step-guide"
              data-testid="list-step-guide"
              class="wizard get-started"
            >
              <li
                v-for="(item, index) in getStarted"
                :key="index"
                :id="`item-step-guide-${index+1}`"
                :data-testid="`item-step-guide-${index+1}`"
                class="item-list padding-x-24"
                :title="item.title"
                @click="handleUrlStep(item.id)"
              >
                <!-- @click="setStep(item.id)" -->
                <div
                  :id="`content-step-${index+1}`"
                  :data-testid="`content-step-${index+1}`"
                  class="wizard-item"
                  :class="{
                    'wizard-item__disable': item.disable,
                    'wizard-item__visited': item.visited,
                    'wizard-item__active': item.active,
                  }"
                >
                  <div
                    :id="item.id"
                    :data-testid="`${item.id}-step-guide`"
                    class="item-content"
                  >
                    <div
                      :data-testid="`number-${item.id}step`"
                      class="id-item"
                      :class="{
                        'id-item__visited': item.visited,
                        'id-item__active': item.active,
                      }"
                    >
                      <feather-icon
                        v-if="item.active"
                        icon="CheckIcon"
                        size="14"
                      />
                      <span v-else>
                        {{ item.id }}
                      </span>
                    </div>
                    <div>
                      <div class="title-item">
                        <b-card-title
                          :id="`title-step-${index+1}`"
                          :data-testid="`title-step-${index+1}`"
                          :class="{'color-item-active': !item.disable||item.visited, 'color-item-visited': item.visited}"
                        >
                          {{ item.title }}
                        </b-card-title>
                      </div>
                      <div
                        :id="`desc-step-${index+1}`"
                        :data-testid="`desc-step-${index+1}`"
                        class="content-item"
                      >
                        <b-card-text>
                          {{ item.desc }}
                        </b-card-text>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="wizard-radios-container">
          <b-card-title class="text-title padding-x-24 mb-0">
            {{ $t('stepGuideControl.textRunYourCampaign') }}
          </b-card-title>
          <div class="nav-menu">
            <ul
              id="list-step-run-campaign"
              data-testid="list-step-run-campaign"
              class="wizard run-campaign pt-2"
            >
              <li
                v-for="(item, index) in runYourCampaign"
                :key="index"
                :id="`item-step-run-campaign-${index+1}`"
                :data-testid="`item-step-run-campaign-${index+1}`"
                class="item-list padding-x-24"
                :title="item.title"
                @click="handleUrlStep(item.id)"
              >
                <!-- @click="setStep(item.id)" -->
                <div
                  :id="`content-step-run-campaign-${index+1}`"
                  :data-testid="`content-step-run-campaign-${index+1}`"
                  class="wizard-item"
                  :class="{
                    'wizard-item__disable': item.disable,
                    'wizard-item__visited': item.visited,
                    'wizard-item__active': item.active,
                  }"
                >
                  <div
                    :id="item.id"
                    class="item-content"
                  >
                    <div
                      class="id-item"
                      :class="{
                        'id-item__visited': item.visited,
                        'id-item__active': item.active,
                      }"
                    >
                      <feather-icon
                        v-if="item.active === true"
                        icon="CheckIcon"
                        size="14"
                      />
                      <span v-else>
                        {{ item.id }}
                      </span>
                    </div>
                    <div>
                      <div
                        class="title-item"
                      >
                        <b-card-title
                          :id="`title-step-run-campaign-${index+1}`"
                          :data-testid="`title-step-run-campaign-${index+1}`"
                          :class="{'color-item-active': !item.disable||item.visited, 'color-item-visited': item.visited}"
                        >
                          {{ item.title }}
                        </b-card-title>
                      </div>
                      <div class="content-item">
                        <b-card-text
                          :id="`desc-step-run-campaign-${index+1}`"
                          :data-testid="`desc-step-run-campaign-${index+1}`"
                        >
                          {{ item.desc }}
                        </b-card-text>
                        <div v-if="item.subDesc">
                          <ul class="sub-desc">
                            <li
                              v-for="(text, id) in item.subDesc"
                              :key="id"
                            >
                              {{ text }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import stepGuideMixin from '@/mixins/stepGuideMixin'
import { BCardTitle, BCardText } from 'bootstrap-vue'
import { mapGetters, createNamespacedHelpers } from 'vuex'
import { ONBOARDING_STEP, TRANSACTION_TYPE } from '@/constants'
import partnerMixin from '@/mixins/partnerMixin'
import envMixin from '@/mixins/envMixin'
import useVerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/useVerticalNavMenu'
import paymentMixin from '@/mixins/paymentMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'

const { mapActions } = createNamespacedHelpers('payment')

export default {
  components: {
    BCardTitle,
    BCardText,
  },

  mixins: [stepGuideMixin, partnerMixin, envMixin, paymentMixin, generalConfigsMixin],

  setup() {
    const {
      toggleCollapsed,
    } = useVerticalNavMenu()

    return {
      toggleCollapsed,
    }
  },

  data() {
    return {
      getStarted: [
        {
          id: 1,
          title: this.$t('stepGuideControl.textSignUpSuccess'),
          desc: `${this.$t('stepGuideControl.textYouHaveSuccessfullyRegistered')} ${this.$t('stepGuideControl.textAccountPlatform', { platformName: this.platformName })}`,
          disable: true,
        },
        {
          id: ONBOARDING_STEP.UPDATING_CONTACT_INFO,
          title: this.$t('stepGuideControl.titleStep2'),
          desc: this.$t('stepGuideControl.descStep2'),
        },
        {
          id: ONBOARDING_STEP.WAITING_VERIFY_CONTACT_INFO,
          title: this.$t('stepGuideControl.titleStep3'),
          desc: this.$t('stepGuideControl.descStep3'),
        },
      ],

      runYourCampaign: [
        {
          id: ONBOARDING_STEP.DEPOSITING_MONEY,
          title: this.$t('stepGuideControl.textAddFund'),
          desc: this.$t('stepGuideControl.textAddFundTo', { platform: `${process.env.VUE_APP_PLATFORM_NAME}` }),
        },
        {
          id: ONBOARDING_STEP.REQUESTING_ADS_ACCOUNT,
          title: this.$t('stepGuideControl.textCreateAdAccount'),
          desc: this.$t('stepGuideControl.descStep5'),
        },
      ],

      currentPage: 1,
      pageLength: 10,
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      loadingUser: 'auth/loading',
      loadingPayment: 'payment/loading',
      loadingAdsAccount: 'adsAccount/loading',
      tazapayPending: 'payment/tazapayPending',
    }),
  },

  watch: {
    user: {
      handler(userInfo) {
        if (userInfo) {
          this.setGetStarted()
          this.setRunYourCampaign()
          const step = userInfo?.data?.onboardingStep
          this.handleStep(step)
        }
      },
      deep: true,
      immediate: true,
    },

    generalConfigs: {
      handler(val) {
        if (val?.system) {
          this.setGetStarted()
          this.setRunYourCampaign()
          const step = this.user?.data?.onboardingStep
          this.handleStep(step)
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    window.history.pushState(null, null, window.location.href)
    window.onpopstate = function () {
      window.history.go(1)
    }

    const step = this.user?.data?.onboardingStep

    this.handleStep(step)

    const { fullPath } = this.$route
    const checkUrl = fullPath.toLowerCase()
      .includes('/payment/failed')

    const checkInprogress = fullPath.toLowerCase()
      .includes('payment/tazapay/progress')

    const hasPending = this.tazapayPending?.payload

    if (checkUrl || checkInprogress || hasPending) {
      this.$router.push(fullPath)
    } else {
      this.handleUrlStep(step)
    }

    this.fetchRefundHistory()
  },

  methods: {
    ...mapActions(['getHistory']),

    setGetStarted() {
      const trialDays = this.user?.data?.plans?.subscription?.trial_days || 14
      this.getStarted = [
        {
          id: 1,
          title: this.subscriptionMode && this.trialMode
            ? `${this.$t('stepGuideControl.textActive')} ${trialDays} ${this.$t('stepGuideControl.textDayFreeTrial')}`
            : this.$t('stepGuideControl.textSignUpSuccess'),
          desc: this.subscriptionMode && this.trialMode
            ? `${this.$t('stepGuideControl.textFree1AdAccount')} ${trialDays} ${this.$t('stepGuideControl.textDay')}.`
            : `${this.$t('stepGuideControl.textYouHaveSuccessfullyRegistered')} ${this.$t('stepGuideControl.textAccountPlatform', { platformName: this.platformName })}`,
          disable: true,
        },
        {
          id: ONBOARDING_STEP.UPDATING_CONTACT_INFO,
          title: this.$t('stepGuideControl.titleStep2'),
          desc: this.$t('stepGuideControl.descStep2'),
        },
        {
          id: ONBOARDING_STEP.WAITING_VERIFY_CONTACT_INFO,
          title: this.$t('stepGuideControl.titleStep3'),
          desc: this.$t('stepGuideControl.descStep3'),
        },
      ]
    },

    setRunYourCampaign() {
      this.runYourCampaign = [
        {
          id: ONBOARDING_STEP.DEPOSITING_MONEY,
          title: this.isSetupCostModel ? this.$t('stepGuideControl.textSetupCost') : this.$t('stepGuideControl.textAddFund'),
          desc: `${this.isSetupCostModel ? this.$t('stepGuideControl.textOneTimeSetupCost') : this.$t('stepGuideControl.textAddFundTo', { platform: `${process.env.VUE_APP_PLATFORM_NAME}` })}`,
        },
        {
          id: ONBOARDING_STEP.REQUESTING_ADS_ACCOUNT,
          title: this.$t('stepGuideControl.textCreateAdAccount'),
          desc: this.$t('stepGuideControl.descStep5'),
        },
      ]
    },

    async fetchRefundHistory() {
      const params = {
        page: this.currentPage,
        size: this.pageLength,
        types: [TRANSACTION_TYPE.REFUND_USER_BALANCE],
      }

      await this.getHistory({
        type: 'refund_user_balance',
        params,
      })
    },

    handleStep(step) {
      const listStepStart = this.getStarted.map(item => {
        if (item.id < step) {
          item.active = true
          item.visited = false
        }

        if (item.id === step) {
          item.visited = true
          item.active = false
        }

        if (item.id > step) {
          item.disable = true
        }

        return item
      })

      const listStepRun = this.runYourCampaign.map(item => {
        if (item.id < step) {
          item.active = true
          item.visited = false
        }

        if (item.id === step) {
          item.visited = true
          item.active = false
        }

        if (item.id > step) {
          item.disable = true
        }
        return item
      })

      this.getStarted = listStepStart
      this.runYourCampaign = listStepRun
    },

    handleUrlStep(step) {
      if (this.isPartnerViewMode === 'true') {
        this.$router.push({ name: 'partner-management' })
      } else if (step <= this.setupGuideStep && this.hasStepGuide && step !== 1
      // && !this.loadingUser && !this.loadingPayment && !this.loadingAdsAccount
      ) {
        switch (step) {
          case ONBOARDING_STEP.UPDATING_CONTACT_INFO: {
            this.$router.push({ name: 'update-information-business' })
            this.toggleCollapsed()
            break
          }
          case ONBOARDING_STEP.WAITING_VERIFY_CONTACT_INFO: {
            this.$router.push({ name: 'waiting-verify-information' })
            this.toggleCollapsed()
            break
          }
          case ONBOARDING_STEP.DEPOSITING_MONEY: {
            const tabHistory = this.$route.query?.tab
            if (this.hasPayFast) {
              this.$router.push('/payment/add-fund?tab=payfast&type=recharge')
            } else if (this.isOnlyEcomdyPlatform) {
              this.$router.push(`/payment/add-fund?${tabHistory ? `tab=${tabHistory}` : 'tab=paypal&type=recharge'}`)
            } else {
              this.$router.push(`/payment/add-fund?${tabHistory ? `tab=${tabHistory}` : 'type=recharge'}`)
            }
            break
          }
          case ONBOARDING_STEP.REQUESTING_ADS_ACCOUNT: {
            this.$router.push({ name: 'my-ads-account' })
            break
          }
          default: {
            this.$router.push({ name: 'my-ads-account' })
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@import url("https://fonts.googleapis.com/icon?family=Open+Sans");

.padding-x-24 {
  padding: 0 24px;
}

.setup-content {
  background-color: #fff;

  border-radius: var(--border-radius-base);

  padding: 24px 0;

  @media(max-width: 767px) {
    padding: 24px 20px 24px 24px;
  }

  .title-content {
    color: #000000;
    font-size: 20px;

    margin-bottom: 32px;
  }

  .steps-setup {
    .wizard-radios-container {
      height: max-content;

      .text-title {
        color: rgba(0, 0, 0, 0.502);
      }

      .nav-menu {
        .wizard {
          list-style: none;

          margin: 0;
          padding: 0;

          overflow: hidden;

          .wizard-item {
            cursor: pointer;

            position: relative;
            z-index: 2;

            height: 120px;

            .item-content {
              // padding-bottom: 30px;

              display: flex;
            }

            .item-content:hover {
              .color-item-active {
                color: var(--step-guide-color);
              }
            }

            .color-item-visited {
              color: var(--step-guide-color);
            }

            .id-item {
              margin-right: 21px;

              color: #000;
              background-color: #f5f5f7;

              max-width: 30px;
              max-height: 30px;
              width: 100%;

              border-radius: 50%;

              display: flex;
              justify-content: center;
              align-items: center;

              &__visited,
              &__active {
                background-color: var(--step-guide-color);

                color: #fff;
              }
            }

            .title-item {
              .card-title {
                margin-bottom: 3px;

                font-size: 16px;
              }
            }

            .content-item {
              font-size: 14px;

              .card-text {
                margin-bottom: 0;

                color: #000000;
              }
            }

            &__disable {
              cursor: not-allowed;
            }

            @media (max-width: 1440px) {
              height: 120px;
            }
          }
        }
      }
    }
  }

  .sub-desc {
    padding: 0 1rem;
    list-style: decimal;
  }

  .wizard li:not(:first-child) > .wizard-item::before {
    content: "";
    position: absolute;
    width: 4px;
    height: 100%;
    left: 14px;
    top: -75%;
    z-index: -1;
    // background: #4cb375;
    background: #f5f5f7;

    @media (max-width: 1440px) {
      top: -75%;
    }
  }

  .wizard-item__visited {
    cursor: pointer !important;
    z-index: 999 !important;
  }

  .wizard li:not(:first-child) > .wizard-item__visited::before {
    background: var(--step-guide-color);
    // animation: effectStep 1s ease-in-out;
  }

  .wizard li:not(:first-child) > .wizard-item__active::before {
    background: var(--step-guide-color);
    // animation: effectStep 1s ease-in-out;
  }

  // @keyframes effectStep {
  //   0%{
  //     height: 0%;
  //   }
  //   100%{
  //     height: 100%;
  //   }
  // }
}
</style>
