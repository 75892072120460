<template>
  <b-modal
    ref="adreach-survey-modal"
    id="adreach-survey-modal"
    size="lg"
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-between top-modal px-1 pt-1">
      <div>
        <h3 class="font-medium text-primary">
          {{ $t('home.adreachSurveyModal.adreachSurveyTitle', {platform: platformName}) }}
        </h3>
        <p class="text-primary">
          {{ $t('home.adreachSurveyModal.adreachSurveyContent') }}
        </p>
      </div>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>

    <validation-observer
      ref="adreachSurvey"
      v-slot="{ invalid }"
    >
      <b-form-group class="list-survey">
        <b-form-checkbox-group
          v-model="selected"
          class="d-flex flex-wrap justify-content-between"
          @change="handleChangeAdreach"
        >
          <b-form-checkbox
            v-for="survey in listSurvey"
            :key="survey.value"
            :value="survey.value"
            class="d-flex justify-content-between survey-items"
            :class="`survey-items-${survey.order}`"
          >
            <div class="d-flex align-items-center">
              <b-img
                class="mr-1"
                :src="survey.img"
              />
              <span>{{ survey.text }}</span>
            </div>
          </b-form-checkbox>

          <validation-provider
            v-if="isChooseOther"
            #default="{ errors }"
            class="other-adreach survey-items-10"
            :name="$t('home.adreachSurveyModal.placeholderOtherAdreach')"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                v-model.trim="otherAdreach"
                id="other-adreach"
                name="otherAdreach"
                class="input-height input-other-adreach"
                :state="errors.length > 0 ? false : null"
                placeholder="Enter other information"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-checkbox-group>
      </b-form-group>

      <div class="d-flex justify-content-between align-items-center">
        <p class="text-danger">
          {{ messageError }}
        </p>
        <div class="btn-control d-flex justify-content-end mt-1">
          <btn-loading
            class="font-medium font-14 mr-2"
            variant-convert="btn-back"
            pill
            @click="hideModal"
          >
            {{ $t('home.adreachSurveyModal.noThank') }}
          </btn-loading>
          <btn-loading
            type="submit"
            variant-convert="btn-submit"
            pill
            :loading="loading"
            @click.prevent="submitAdreachSurvey(invalid)"
          >
            <span>{{ $t('home.adreachSurveyModal.submit') }}</span>
          </btn-loading>
        </div>
      </div>
    </validation-observer>
  </b-modal>
</template>
<script>
/* eslint-disable global-require */
import {
  BModal,
  BImg,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormInput,
  BInputGroup,
} from 'bootstrap-vue'
import envMixin from '@/mixins/envMixin'
import { createNamespacedHelpers } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('partnerShip')

export default {
  components: {
    BModal,
    BImg,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormInput,
    BInputGroup,
    BtnLoading,
    // validations
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [envMixin],

  props: {
    userInfo: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      selected: [],
      otherAdreach: '',
      isChooseOther: false,
      messageError: '',

      required,
    }
  },

  computed: {
    ...mapGetters(['loading', 'status']),
    listSurvey() {
      return [
        {
          value: 'Facebook/Instagram',
          img: require('@/assets/images/icons/ic-facebook.svg'),
          text: 'Facebook/Instagram',
          order: 1,
        },
        {
          value: 'Word of mouth',
          img: require('@/assets/images/icons/ic-word-of-mouth.svg'),
          text: 'Word of mouth',
          order: 2,
        },
        {
          value: 'TikTok',
          img: require('@/assets/images/icons/ic-tiktok.svg'),
          text: 'TikTok',
          order: 3,
        },
        {
          value: 'Influencers',
          img: require('@/assets/images/icons/ic-influencers.svg'),
          text: 'Influencers',
          order: 4,
        },
        {
          value: 'Youtube',
          img: require('@/assets/images/icons/ic-youtube.svg'),
          text: 'Youtube',
          order: 5,
        },
        {
          value: 'E-commerce meetup/event',
          img: require('@/assets/images/icons/ic-ecommerce.svg'),
          text: 'E-commerce meetup/event',
          order: 6,
        },
        {
          value: 'Google',
          img: require('@/assets/images/icons/ic-google.svg'),
          text: 'Google',
          order: 7,
        },
        {
          value: 'Friends/Relatives',
          img: require('@/assets/images/icons/ic-freind.svg'),
          text: 'Friends/Relatives',
          order: 9,
        },
        {
          value: 'other',
          img: require('@/assets/images/icons/ic-other.svg'),
          text: this.$t('home.adreachSurveyModal.other'),
          order: 8,
        },
      ]
    },
  },

  methods: {
    ...mapActions(['adreachSurvey']),

    hideModal() {
      this.resetModal()
      this.$refs['adreach-survey-modal'].hide()
      localStorage.setItem('isDoneSurvey', 'true')
    },

    showModal() {
      this.$refs['adreach-survey-modal'].show()
    },

    resetModal() {
      this.selected = []
      this.otherAdreach = ''
      this.messageError = ''
    },

    handleChangeAdreach() {
      this.isChooseOther = this.selected.find(item => item === 'other')
      if (this.selected.length !== 0) {
        this.messageError = ''
      }
    },

    async submitAdreachSurvey() {
      const success = await this.$refs.adreachSurvey.validate()
      if (this.selected.length === 0) {
        this.messageError = this.$t('home.adreachSurveyModal.adreachSurveyInvalid')
      } else {
        this.messageError = ''
      }
      if (success && this.selected.length > 0) {
        this.handleAdreachSurvey()
      }
    },

    async handleAdreachSurvey() {
      let listAdreachFull = []
      if (this.isChooseOther) {
        const adreachSelected = this.selected.filter(item => item !== 'other')
        listAdreachFull = [...adreachSelected, this.otherAdreach]
      } else {
        listAdreachFull = this.selected
      }
      const params = {
        hearAbouts: listAdreachFull,
      }
      await this.adreachSurvey(params)
      if (this.status) {
        this.hideModal()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.btn-hide{
  background-color: rgba(204, 204, 204, 0.5);
  padding: 6px;
  border-radius: 50%;
  line-height: 0;
}

.img-gift-box{
  width: max-content;

  img{
    width: 240px;
  }
}

.content-gift{
  text-align: center;
}

.survey-items {
  margin: 24px 0 0;
  border-radius: 16px;
  width: 48.5%;
  padding: 24px 12px 24px 24px;
  background-color: #F5F5F7;

  @media(max-width: 767px) {
    width: 100%;
  }
}

.other-adreach {
  width: 48.5%;
  margin: 24px 0 0;

  @media(max-width: 767px) {
    width: 100%;
  }
}

.btn-control {
  margin-bottom: 20px;
}

.list-survey {
  @media(min-width: 768px) {
    .survey-items-8 {
      order: 8;
    }
    .survey-items-9 {
      order: 9;
    }
    .survey-items-10 {
      order: 10;
    }
  }
}
</style>
<style lang="scss">
.list-survey .custom-checkbox .custom-control-label::before,.list-survey .custom-checkbox .custom-control-label::after {
  left: 88%;
  top: 39%;
}

.list-survey .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  box-shadow: 0 2px 4px 0 rgba(76, 179, 117, 0.4) !important;
}

.list-survey .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after, .list-survey .custom-checkbox .custom-control-input:active ~ .custom-control-label::after {
  background-color: #4CB375;
}

.other-adreach .input-group, .other-adreach .input-other-adreach {
  height: 100%;
}

#adreach-survey-modal {
  .modal-dialog {
    @media (min-width: 768px) and (max-width: 991px) {
      max-width: 744px !important;
    }
  }
}
</style>
